import OutboundLink from '../../OutboundLink'
import * as style from './style.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'

export default function VerticalSnappySlide(props) {
  return (
    <div className={style.mediaContainer}>
      <div className={style.media}>
        {props.title && <div className={style.title}>{props.title}</div>}
        {props.subline && <div className={style.subline}>{props.subline}</div>}
        {props.linkTitle && (
          <OutboundLink
            href={props.link}
            eventLabel={props.eventLabel}
            target='_blank'
          >
            {props.linkTitle}
          </OutboundLink>
        )}
      </div>
    </div>
  )
}
