import SnappySlider from '../SnappySlider'
import VerticalSnappySlide from '../SnappySlider/VerticalSnappySlide'
import Template from '../Template'
import DogSitter1 from './dog-sitter-1.png'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageForDogSitters = (props) => {
  return (
    <Template>
      <Helmet>
        <title>For dog owners - {props.data.site.siteMetadata.title}</title>
      </Helmet>
      <div className={style.heroContainer}>
        <h1>The best platform for in-home dog sitting</h1>
        <p className={style.subline}>
          What makes doggos.com so different? <br />
          <i>...everything.</i>
        </p>
      </div>

      <div className={style.trust}>
        <div className={style.copy}>
          <h2>In-home dog sitting is great</h2>
          <p>
            <strong>We're making it better</strong>
          </p>
          <p>
            In-home dog sitters offer the experience pet owners want when they
            have to leave their dog behind while traveling.
          </p>
          <p>
            A real home provides a loving, safe, and familiar environment. We
            provide the rules, high standards, and support that small businesses
            have been asking for in a platform.
          </p>
        </div>
        <div>
          <img className={style.coverPhoto} src={DogSitter1}></img>
        </div>
      </div>

      <div className={style.snappySlider}>
        <div className={style.sliderCopy}>
          <h2>We believe that every dog deserves quality care</h2>
          <p className={style.subline}>
            Not that “every person can be a dog sitter.”
          </p>
        </div>
        <SnappySlider>
          <VerticalSnappySlide
            title='It starts with an application'
            subline='Every new sitter goes through a multi-step application process, including a background check and knowledge test.'
          />
          <VerticalSnappySlide
            title='The "Sitter Interview"'
            subline="We have high standards for sitters, so you can be part of a community that you're proud of."
          />
          <VerticalSnappySlide
            title='Ongoing support & platform rules'
            subline="We offer seminars, check-in's, and guidance to help sitters make the most of their presence on doggos.com."
          />
        </SnappySlider>
      </div>

      <div className={style.benefits}>
        <h2>Platform benefits</h2>
        <div className={style.textBlockContainer}>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              0 fees for sitters, payouts in 24 hours or less
            </h3>
            <p>
              Doggos.com is free to use for sitters that successfully complete
              our application process, and all bookings are paid out to sitters
              the very next day.
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>Free advertising for your business</h3>
            <p>
              We advertise doggos.com in all the cities we operate in, and in
              our email communications. Completely free to you!
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              Ongoing support directly from the doggos.com team
            </h3>
            <p>
              We work with sitters directly to understand their goals and make
              sure they're getting the best from the platform
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>The latest technology</h3>
            <p>
              Custom profiles, a super-flexible calendar & rates dashboard, and
              comprehensive analytics are just the start of what makes
              doggos.com the best. Our design, UX, and features are
              world-class... and we're always adding more!
            </p>
          </div>
        </div>
      </div>

      <div className={style.buttonContainer}>
        <Link className={style.buttonApply} to='/sitter-application'>
          Apply to be a dog sitter
        </Link>
        <Link className={style.button} to='/doggos-vs-rover-vs-wag'>
          Learn more about us
        </Link>
      </div>
    </Template>
  )
}
export default PageForDogSitters
