import PageForDogSitters from '../components/PageForDogSitters'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageForDogSitters {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageForDogSitters
